import {NgModule} from '@angular/core';
import {CommonModule, provideImageKitLoader, NgOptimizedImage} from '@angular/common';
import {QuoteRightSidebarComponent} from './sidebar/quote-right-sidebar/quote-right-sidebar.component';
import {SampleOrderRightSidebarComponent} from './sidebar/sample-order-right-sidebar/sample-order-right-sidebar.component';
import {AdhesivePremiumSealingInfoModelComponent} from './modal/adhesive-premium-sealing-info-model/adhesive-premium-sealing-info-model.component';
import {MinimumOrderQuantityModalComponent} from "./modal/minimum-order-quantity-modal/minimum-order-quantity-modal.component";
import {RouterModule} from "@angular/router";
import {DonationThankYouModalComponent} from './modal/donation-thank-you-modal/donation-thank-you-modal.component';
import {CoreModule} from "../core/core.module";
import {AccessorySectionComponent} from './accessory-section/accessory-section.component';
import {TermAndConditionSectionComponent} from './term-and-condition-section/term-and-condition-section.component';
import {PaymentReceiptModalComponent} from './modal/payment-receipt-modal/payment-receipt-modal.component';
import {QuoteInvoiceShareModalComponent} from './modal/quote-invoice-share-modal/quote-invoice-share-modal.component';
import {DeliveryCostApprovalModalComponent} from './modal/delivery-cost-approval-modal/delivery-cost-approval-modal.component';
import {RequestDeliveryQuoteModalComponent} from './modal/request-delivery-quote-modal/request-delivery-quote-modal.component';
import {AcceptQuoteModalComponent} from './modal/accept-quote-modal/accept-quote-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuoteInvoiceExportModalComponent} from './modal/quote-invoice-export-modal/quote-invoice-export-modal.component';
import {RatingGuideModalComponent} from './modal/rating-guide-modal/rating-guide-modal.component';
import {ReceiptExportModalComponent} from './modal/receipt-export-modal/receipt-export-modal.component';
import {NgxOtpInputModule} from 'ngx-otp-input';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {BpayPaymentThankYouModalComponent} from './modal/bpay-payment-thank-you-modal/bpay-payment-thank-you-modal.component';
import {UploadPartialPaymentReceiptModalComponent} from './modal/upload-partial-payment-receipt-modal/upload-partial-payment-receipt-modal.component';
import {environment} from 'src/environments/environment';
import {SampleOrderConfirmationComponent} from '../shared/modal/sample-order-confirmation/sample-order-confirmation.component';
import {UploadMultipleFileDirective} from '../core/directives/upload-multiple-file.directive';
import {UpdateSiteContactDetailComponent} from './modal/update-site-contact-detail/update-site-contact-detail.component';
import {IMaskModule} from "angular-imask";
import {TrackOrderShareModelComponent} from './modal/track-order-share-model/track-order-share-model.component';
import {BsDatepickerConfig, BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {CommonDateSelectPopupComponent} from './modal/common-date-select-popup/common-date-select-popup.component';
import {StatusAlertMessageComponent} from './status-alert-message/status-alert-message.component';
import { UpdateDeliveryInstructionModalComponent } from './modal/update-delivery-instruction-modal/update-delivery-instruction-modal.component';
import { WaSampleRequestRestrictionModalComponent } from './modal/wa-sample-request-restriction-modal/wa-sample-request-restriction-modal.component';


@NgModule({
  declarations: [ // Other shared components, directives, and pipes...
    QuoteRightSidebarComponent,
    SampleOrderRightSidebarComponent,
    AdhesivePremiumSealingInfoModelComponent,
    MinimumOrderQuantityModalComponent,
    DonationThankYouModalComponent,
    AccessorySectionComponent,
    TermAndConditionSectionComponent,
    PaymentReceiptModalComponent,
    QuoteInvoiceShareModalComponent,
    DeliveryCostApprovalModalComponent,
    RequestDeliveryQuoteModalComponent,
    AcceptQuoteModalComponent,
    QuoteInvoiceExportModalComponent,
    RatingGuideModalComponent,
    ReceiptExportModalComponent,
    BpayPaymentThankYouModalComponent,
    UploadPartialPaymentReceiptModalComponent,
    SampleOrderConfirmationComponent,
    UploadMultipleFileDirective,
    UpdateSiteContactDetailComponent,
    TrackOrderShareModelComponent,
    CommonDateSelectPopupComponent,
    StatusAlertMessageComponent,
    UpdateDeliveryInstructionModalComponent,
    WaSampleRequestRestrictionModalComponent
  ],
  imports: [ // Other modules you might need...
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxOtpInputModule,
    GooglePlaceModule,
    NgOptimizedImage,
    IMaskModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [ // Other shared components, directives, and pipes...
    QuoteRightSidebarComponent,
    SampleOrderRightSidebarComponent,
    AdhesivePremiumSealingInfoModelComponent,
    MinimumOrderQuantityModalComponent,
    DonationThankYouModalComponent,
    AccessorySectionComponent,
    TermAndConditionSectionComponent,
    PaymentReceiptModalComponent,
    QuoteInvoiceShareModalComponent,
    DeliveryCostApprovalModalComponent,
    RequestDeliveryQuoteModalComponent,
    AcceptQuoteModalComponent,
    UploadMultipleFileDirective,
    CommonDateSelectPopupComponent,
    StatusAlertMessageComponent
  ],
  providers: [
    provideImageKitLoader(environment.imageKitEndpoint),
    BsDatepickerConfig
  ]

})
export class SharedModule {
}
