import {environment} from "../../../environments/environment";

export class CommonConstants {
  /*## Interceptor Error Message ##*/
  public static FORBIDDEN = 'Forbidden request';
  public static INTERNAL_SERVER_ERROR = 'Internal server error';
  public static BED_REQUEST = 'Bad request';
  public static UNAUTHORIZED_PASSWORD_TOKEN = 'Unauthorized reset-password token';
  public static REQUEST_NOT_FOUND = 'Request not found';
  public static METHOD_NOT_ALLOW = 'Method not allowed';
  public static NOT_ACCEPTABLE = 'Not acceptable';
  public static REQUEST_ENTITY_TOO_LARGE = 'Request entity too large';
  public static UNSUPPORTED_MEDIA = 'Unsupported media type';
  public static REQUEST_TIME_OUT = 'Request timeout';
  public static NOT_IMPLEMENTED = 'Not implemented';
  public static BED_GATEWAY = 'Bad gateway';
  public static SERVICE_UNAVAILABLE = 'Service unavailable';
  public static GATEWAY_TIME_OUT = 'Gateway timeout';
  /*## Interceptor Error Message ##*/

  /*## Common Constants ##*/
  public static IS_DEFAULT_ADDRESS_YES = 'Y';
  public static IS_DEFAULT_ADDRESS_NO = 'N';
  public static USER_TYPE_TRADE = 'Trade';
  public static USER_TYPE_GENERAL = 'General';
  public static STATUS_ACTIVE = 'A';
  public static PAGE_SIZE_ALL = 'all';
  public static MALINA_FAMILY_ID = 40;
  public static MALINA_FAMILY_FEATURE_PRODUCT_SKU = '5MK201'; //5MK202 MIXED
  public static WAREHOUSE_COLLECTION_TRUCK_TYPE = 'Client Organized Order Collection';
  public static WAREHOUSE_COLLECTION_DELIVERY_ZONE_NAME = 'GatherCo Warehouse';
  public static SURFACE_SORT_NAME = 'S';
  public static DECOR_SORT_NAME = 'D';
  public static PRODUCT_TYPE_SM = 'SM';
  public static IS_DISPLAY_TRANSACTION_HISTORY = 'Y';
  public static PO_BOX_ADDRESS_REGEX = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i
  public static DOWNLOAD_QUOTE = 'DQ';
  public static DOWNLOAD_INVOICE = 'DI';
  public static DOWNLOAD_PAYMENT_RECEIPT = 'DPR';
  public static SHARE_QUOTE = 'SQ';
  public static SHARE_INVOICE = 'SI';
  public static SHARE_TRACKING_LINK = 'STL';
  public static SHARE_PAYMENT_RECEIPT = 'SPR';
  public static ORDER_TAB_ALL = 'all-order';
  public static ORDER_TAB_AWAITING_PAYMENT = 'awaiting-payment';
  public static ORDER_TAB_PAYMENT_DUE = 'payment-due';
  public static ORDER_TAB_PAYMENT_COMPLETE = 'payment-complete';
  public static ORDER_TAB_COMPLETE = 'order-complete';
  public static ORDER_TAB_CANCELLED = 'order-cancelled';
  public static ORDER_TAB_ALL_TITLE = 'All Orders';
  public static ORDER_TAB_AWAITING_PAYMENT_TITLE = 'Awaiting Payment';
  public static ORDER_TAB_PAYMENT_DUE_TITLE = 'Payment Due';
  public static ORDER_TAB_PAYMENT_COMPLETE_TITLE = 'Payment Complete';
  public static ORDER_TAB_COMPLETE_TITLE = 'Order Complete';
  public static ORDER_TAB_CANCELLED_TITLE = 'Order Cancelled';
  public static ORDER_TAB_ALL_NO_RECORD_MESSAGE = 'You currently have no orders owing';
  public static ORDER_TAB_AWAITING_PAYMENT_NO_RECORD_MESSAGE = 'You currently have no awaiting payments owing';
  public static ORDER_TAB_PAYMENT_DUE_NO_RECORD_MESSAGE = 'You currently have no payment due owing';
  public static ORDER_TAB_PAYMENT_COMPLETE_NO_RECORD_MESSAGE = 'You currently have no completed payment owing';
  public static ORDER_TAB_COMPLETE_NO_RECORD_MESSAGE = 'You currently have no completed order owing';
  public static ORDER_TAB_CANCELLED_NO_RECORD_MESSAGE = 'You currently have no cancelled order owing';
  public static ORDER_BY_STATUS_TAB_ALL_NO_RECORD_MESSAGE = 'No <span>all</span> orders available';
  public static ORDER_BY_STATUS_TAB_AWAITING_PAYMENT_NO_RECORD_MESSAGE = 'No <span>awaiting payment</span> orders available';
  public static ORDER_BY_STATUS_TAB_PAYMENT_DUE_NO_RECORD_MESSAGE = 'No <span>payment due</span> orders available';
  public static ORDER_BY_STATUS_TAB_PAYMENT_COMPLETE_NO_RECORD_MESSAGE = 'No <span>payment complete</span> orders available';
  public static ORDER_BY_STATUS_TAB_COMPLETE_NO_RECORD_MESSAGE = 'No <span>complete</span> orders available';
  public static HERO_FORMAT_SKU_YES = 'YES';
  public static IS_FEATURE_PROFILE_NO = 'NO';
  public static STATUS_ACTIVE_SHORT = 'A';
  public static SAMPLE_PRODUCT_SORT_NAME = 'SM';
  public static TAX_GST_PERCENTAGE = 10;
  // public static DOWNLOAD_ADHESIVE_AND_PREMIUM_SEALING_PROCEDURES = 'https://www.dropbox.com/s/pg1rxuqzyjgqx8g/Sealing_Procedures.zip?dl=0';
  //public static DOWNLOAD_ADHESIVE_AND_PREMIUM_SEALING_PROCEDURES = 'https://www.dropbox.com/sh/765hfidnvvtI74n/AABZ91Wp9WwGOz_J_0Vvw1P9a?dI=0';
  public static DOWNLOAD_ADHESIVE_AND_PREMIUM_SEALING_PROCEDURES = 'https://www.dropbox.com/sh/765hfjdnvvtl74n/AABZ91Wp9WwGOz_J_0Vvw1P9a?dl=0';
  public static CONSUMABLE_CALCULATOR_TYPE_ADHESIVE = 'laticrete_adhesive';
  public static CONSUMABLE_CALCULATOR_TYPE_DEFENDER = 'defender';
  public static CONSUMABLE_CALCULATOR_TYPE_GOLD = 'gold';
  public static FORk_TRUCK_ID = 1;
  public static HIAB_TRUCK_ID = 2;
  public static FLATAB_TRUCK_ID = 3;
  public static OTHER_TRUCK_ID = 4;
  public static IS_TWO_FACTOR_VERIFIED = 'Y'
  public static POP_UP_TYPE_QUOTE_DELIVERY_REQUEST = "quote-delivery-request";
  public static POP_UP_TYPE_DELIVERY_COST_APPROVAL = "delivery-cost-approval";
  public static POP_UP_TYPE_ACCEPT_QUOTE = "accept-quote";
  public static IS_ARCHIVE_YES = 'Y';
  public static IS_ARCHIVE_NO = 'N';
  public static COLOR_VARIATION_SCORE_ZERO = 'v0';
  public static COLOR_VARIATION_SCORE_ONE = 'v1';
  public static COLOR_VARIATION_SCORE_TWO = 'v2';
  public static COLOR_VARIATION_SCORE_THREE = 'v3';
  public static COLOR_VARIATION_SCORE_FOUR = 'v4';
  public static COLOR_VARIATION_SCORE_FIVE = 'v5';
  public static ARCHIPRO_LINK = 'https://archipro.com.au/professional/gather-co';
  public static PAYMENT_TYPE_FULL = 'FL';
  public static PAYMENT_TYPE_FINAL = 'FP';
  public static PAYMENT_TYPE_DEPOSIT = 'DP';
  public static PAYMENT_PARTIAL_PAYMENT = 'PP';

  public static QUANTITY_LENGTH = 9999
  public static notEditableSiteOrderDetailsValid = ['GDA', 'GDCO', 'GDDE', 'GD', 'FU', 'C'];
  public static FILTER_AVAILABLE_STRING = [
    'Available',
    'available',
  ];
  public static FILTER_LOW_AVAILABLE_STRING = [
    'Low Stock',
  ];

  public static LEAD_TIME = 'Available'
  public static LEAD_TIME_IN_STOCK = 'In Stock'
  public static LEAD_TIME_LOW_STOCK = 'Low Stock'
  public static LATICRETE_AHDESIVE_LINK = 'https://au.laticrete.com/en/our-products/tile-and-stone-installation-system/adhesives-and-mortars/polymer-modified-thin-bed-adhesive/335-premium-flexible-adhesive'
  public static ACTICHEM_CSD_DEFENDER_LINK = 'https://actichem.com.au/product/defender-csd-50-consolidator-water-repellent-for-stone'
  public static ACTICHEM_SOLV_SEALER_GOLD = 'https://actichem.com.au/product/solv-sealer-gold-penetrating-sealer-for-natural-and-concrete/'
  public static TC_OUTLINE_ID = "outline"
  public static TC_QUOTES_AND_ORDERS_ID = "quotes-and-orders"
  public static TC_PRICE_ID = "price"
  public static TC_PAYMENT_ID = "payment"
  public static TC_PAYMENT_DEFAULT_ID = "payment-default"
  public static TC_DELIVERY_INSURANCE_ID = "delivery-insurance"
  public static TC_SUSPENSION_CANCELLATION_ID = "suspension-cancellation"
  public static TC_DEFECTS_RETURNS_ID = "defects-returns"
  public static TC_EXCLUSIONS_LIMITATIONSE_ID = "exclusions-limitations"
  public static TC_STATUTORY_RIGHTS_ID = "statutory-rights"
  public static TC_TITLE_ID = "title"
  public static TC_SECURITY_INTERESTE_ID = "security-interest"
  public static TC_INTELLECTUAL_PROPERTY_ID = "intellectual-property"
  public static TC_INTELLECTUAL_GST_ID = "intellectual-gst"
  public static TC_GENERAL_ID = "general"
  public static TC_WARRANTY_DEFECTS_ID = "warranty-defects"
  public static TC_INTERPRETATIONS_DEFINITIONS_ID = "interpretations-definitions"
  public static PRODUCT_DELETED_YES = 1;
  public static PRODUCT_DELETED_NO = 0;
  public static APPLY_GLUE_CALCULATOR_YES = 'YES';
  public static APPLY_GLUE_CALCULATOR_NO = 'NO';
  public static SEAMLESS_ARRAY = [
    'GatherCo_Apis_Limestone_PaulBrace.jpg',
    'GatherCo_Bastion_Limestone_PaulAlexander.png',
    'GatherCo_Bastion_Limestone_PaulBrace.jpg',
    'GatherCo_Bombala_Limestone_PaulAlexander.png',
    'GatherCo_Chesterfield_Marble_PaulAlexander.png',
    'GatherCo_Dias_Granite_PaulAlexander.png',
    'GatherCo_Flinders_Quartz_FabricArchitecture.jpg',
    'GatherCo_Hampton_Quartz_CollinsTurnerArchitects.png',
    'GatherCo_Ironwood_Travertine_PaulAlexander.png',
    'GatherCo_Kesi_Limestone_PaulAlexander.png',
    'GatherCo_Zahri_Limestone_Malina_Terracotta_TheStrandAgency.png',
    'GatherCo_Zahri_Limestone_PaulAlexander.jpg',
    'GatherCo_Zahri_Limestone_PaulAlexander1.png',
    'GatherCo_Zahri_Limestone_PaulAlexander2.png'
  ]
  public static IS_MANUAL_SHIPPING_RATE_CHANGE_YES = 'Y';
  public static IS_MANUAL_SHIPPING_RATE_CHANGE_NO = 'N';
  public static IS_DISABLE_EXPIRY_DEADLINE_YES = 'Y';
  public static FILTER_PREMIUM_SEALER = ['DEFENDER', 'GOLD'];

  public static EXPORT_QUOTE_INVOICE_URL = environment.hostUrl + '/quote-invoice';
  public static EXPORT_ORDER_TRACKING_URL = environment.hostUrl + '/track-order';
  public static NEW_ARRIVAL_PRODUCT_LINK = environment.hostUrl + "/surfaces/taro/marble/plank-tiles/honed"
  public static FEATURED_SURFACE_PRODUCT_LINK_1 = environment.hostUrl + "/surfaces/cook/granite/cobblestones-pitcher-tops/split"
  public static FEATURED_SURFACE_PRODUCT_LINK_2 = environment.hostUrl + "/surfaces/zahri/limestone/crazy-paving/honed"
  /*## Common Constants ##*/

  /*## ImageKit Constants ##*/
  public static S3_BUCKET_URL = environment.imageKitEndpoint + '/WEBSITE_ASSET_STAGING';
  public static NO_IMAGE_PRODUCT = environment.imageKitEndpoint + '/WEBSITE_ASSET_STAGING/no-image-product.svg';
  public static NEW_ARRIVAL_IMG_LINK = environment.imageKitEndpoint + "/IMAGES/SURFACES/TARO/PROFILE/GatherCo_Taro_Hero_Marble_Planks_HonedTumbled.jpg?tr=w-389,h-513"
  public static FEATURED_SURFACE_IMG_LINK_1 = environment.imageKitEndpoint + "/IMAGES/SURFACES/COOK/PROJECT/Square_Crop/GatherCo_Cook_Project_Granite_FullFormatCobble_Split_NathanBurkett_TimothyKaye1.jpg?tr=w-389,h-513"
  public static FEATURED_SURFACE_IMG_LINK_2 = environment.imageKitEndpoint + "/IMAGES/SURFACES/ZAHRI/PROJECT/Square_Crop/GatherCo_Zahri_Project_Limestone_CrazyPaving_HonedTumbled_MikeCassCreative_DesignItLandscapes_JurisPuisens_Woollarah7.jpg?tr=w-389,h-513"
  public static S3_MAKER_MARK_IMAGE_KIT_FOLDER = '/ICONS/MakersMarks'
  public static S3_MAKER_MARK_IMAGE_KIT_URL = environment.imageKitEndpoint + '/ICONS/MakersMarks'
  public static S3_FINISH_SPECIFICATION_IMAGE_KIT_FOLDER = '/ICONS/Specifications'
  public static S3_FINISH_SPECIFICATION_IMAGE_KIT_URL = environment.imageKitEndpoint + this.S3_FINISH_SPECIFICATION_IMAGE_KIT_FOLDER
  public static S3_PRODUCT_IMAGES_IMAGE_KIT_URL = environment.imageKitEndpoint;
  public static S3_ACCOUNT_LOGO_IMAGE_KIT_URL = environment.imageKitEndpoint + '/account-logo'
  public static S3_PRODUCT_RESOURCE_IMAGE_KIT_URL = environment.imageKitEndpoint + ''
  /*## ImageKit Constants ##*/

  /*## General General/Success/Error Message ##*/
  public static SUCCESS_FORGOT_PASSWORD = 'A password reset link was sent to your email. Click the link in the email to create a new password.';
  public static SUCCESS_RESET_PASSWORD = 'Your password has been updated successfully.';
  public static SUCCESS_USER_REGISTERED = 'Your account has been created successfully.';
  public static SUCCESS_CHANGE_PASSWORD = 'Your password has been changed successfully.';
  public static SUCCESS_USER_PROFILE_UPDATE = 'Personal Information has been updated successfully.';
  public static SUCCESS_COMPANY_DETAIL_UPDATE = 'Company information has been updated successfully.';
  public static SUCCESS_ACCOUNT_ADDRESS_CREATE = 'Business address has been added successfully.';
  public static SUCCESS_ACCOUNT_ADDRESS_UPDATE = 'Business address has been updated successfully.';
  public static SUCCESS_ACCOUNT_ADDRESS_DELETE = 'Business address has been deleted successfully.';
  public static SUCCESS_ACCOUNT_ADDRESS_DEFAULT = 'Business address has been set default successfully.';
  public static SUCCESS_HOME_ADDRESS_DELETE = 'Home address has been deleted successfully.';
  public static SUCCESS_HOME_ACCOUNT_ADDRESS_CREATE = 'Home address has been added successfully.';
  public static SUCCESS_HOME_ACCOUNT_ADDRESS_UPDATE = 'Home address has been updated successfully.';
  public static SUCCESS_HOME_ADDRESS_DEFAULT = 'Home address has been set default successfully.';
  public static SUCCESS_PRODUCT_ADDED_FOR_FAVORITE = "This product has now been added to your favourites list.";
  public static SUCCESS_PRODUCT_REMOVED_FOR_FAVORITE = "This product has been removed from your favourites list.";
  public static ERROR_UNAUTHORIZED_ACTION_PERFORMED = "You cannot perform this action. you needs to logged in first!";
  public static ERROR_UNAUTHORIZED_ACCEPT_QUOTE_PERFORMED = "You cannot accept the quote. You needs to logged in first!";
  public static PRODUCT_FINISH_PREVIEW_NOT_AVAILABLE = 'Sorry finish are not available for this product.';
  public static SAMPLE_CREATED_SUCCESS_MESSAGE = 'Sample order has been created successfully.';
  public static SUCCESS_FRONTEND_USER_ADDRESS_CREATE = 'Address has been added successfully.';
  public static SUCCESS_QUOTE_CREATED = 'Quote has been created successfully.';
  public static SUCCESS_QUOTE_ACCEPTED = 'Quote has been accepted successfully.';
  public static SUCCESS_QUOTE_NOT_ACCEPTED = 'Quote has been not accepted.';
  public static SUCCESS_QUOTE_ORDER_DELETED = 'Quote has been deleted successfully.';
  public static SUCCESS_MESSAGE_REACTIVE_QUOTE = 'Quote has been reactivated successfully.';
  public static SUCCESS_INVOICE_EXPORTED = 'Invoice has been sent successfully.';
  public static SUCCESS_MESSAGE_OF_ACCOUNT_LOGO_UPLOADED = 'Company logo has been uploaded successfully.';
  public static SUCCESS_MESSAGE_OF_USER_LOGO_UPLOADED = 'Profile image has been uploaded successfully.';
  public static SUCCESS_VERIFICATION_CODE = 'verification code has been sent to your email.'
  public static SUCCESS_REVIEW_ADD = "Review has been added successfully"
  public static SUCCESS_REVIEW_EDIT = "Review has been updated successfully"
  public static CONFIRM_MESSAGE_QUOTE_EXPIRE = 'Are you sure want to reactivate this quote?';
  public static APPLY_CREDIT_MESSAGE_IF_NOT_SELECTED = 'Please change slider to apply credit';
  public static ORDER_ADDRESS_UNVALID_ALERT_MESSAGE = "Please select another address"
  public static ON_DONATE_CLICK_ERROR_MESSAGE = 'Please select donation amount';
  public static ON_DONATE_SUCCESS = 'Thanks for your donation.';
  public static ON_DONATE_ERROR = 'Donation not done';
  public static QUOTE_ORDER_APPLY_CREDIT_ERROR_MESSAGE = 'Available credit not apply'
  public static QUOTE_ORDER_APPLY_CREDIT_SUCCESS_MESSAGE = 'Available credit apply successfully'
  public static DELETE_FAVORITE_TITLE = 'Delete Favorite';
  public static DELETE_FAVORITE_MESSAGE = 'This product will be delete from favorite.Are you sure you want delete this product?';
  public static PAYMENT_FAILED = 'Payment failed. Please try again';
  public static PAYMENT_SUCCESS = 'Order payment has been successfully completed.';
  public static DEPOSIT_PAYMENT_SUCCESS = 'Order deposit payment has been successfully completed.';
  public static FINAL_PAYMENT_SUCCESS = 'Order final payment has been successfully completed.';
  public static SUCCESS_MESSAGE_ON_EXPORT_PDF_QUOTE = 'Please check your email address for the exported quote PDF.';
  public static SUCCESS_MESSAGE_ON_EXPORT_ORDER_PAYMENT_RECEIPT = 'Please check your email address for the exported payment receipt.';
  public static ERROR_MESSAGE_ON_EXPORT_PDF_QUOTE = 'PDF not exported getting error.';
  public static PRODUCT_ADDED_SUCCESSFULLY = 'Product has been added successfully.';
  public static QUOTE_STATUS_ACCEPT_MESSAGE = 'Quote has been accepted successfully.';
  public static QUOTE_DELIVERY_REQUEST_SENT_MESSAGE = "Delivery quote request have been sent successfully.";
  public static INVALID_URL_ERROR = 'Invalid URL. Please enter valid url';
  public static CART_PRODUCT_EXIST_MESSAGE = 'This product has been already added to your cart.';
  public static CODE_COPIED = 'Code copied to clipboard.';
  public static FRONTEND_PREFIX = 'https://gatherco.com.au'
  public static FRONTEND_PREFIX_WITH_WWW = 'https://www.gatherco.com.au';
  public static WA_POSTAL_CODE_PREFIX = 6;
  public static IN_SHIPPING_ZONE_YES = 'Y';
  public static IN_SHIPPING_ZONE_NO = 'N';
  public static UNIT_OF_MEASUREMENT_SQM = 'SQM';
  public static PIECE_CONSTANT_LONG_NAME = 'PIECE'
  public static PENDING_DEPOSIT_ORDER_EXPIRED_DAYS = 7;
  public static UN_VALID_CREDENTIAL = 'Invalid username and password combination'
  public static STREET_NUMBER_REQUIRED = 'Please enter valid address street number is missing.';
  public static PRODUCT_DETAIL_SIZING_TABLE_TOOLTIP = "Lead times are indicative only. Although we take great care to ensure they're kept accurate, stock levels are constantly fluctuating. Our best advice is always to place orders as early as possible, thus reducing the likelihood of supply related delays disrupting your construction schedule.";
  public static IN_STOCK_PRODUCT_TOOLTIP = "Stocked items have a standard delivery lead time of 5 days, from date of deposit payment";
  public static EMAIL_IS_REQUIRED_FOR_PDF_EXPORT = `Please select or enter email address`;
  public static TRACKING_NUMBER_COPIED = 'Tracking number copied.'

  public static BILLER_CODE_COPY = 'Biller code copied.'
  public static REF_CODE_COPY = 'Ref code copied.'
  public static DUE_AMOUNT_COPY = 'Due amount copied.'


  public static ADDRESS_COPIED = 'Address copied.'
  public static QUOTE_INVOICE_LINK_COPIED = 'Quote invoice link copied.'
  public static ORDER_TRACKING_LINK_COPIED = 'Order tracking link copied.'
  public static SUCCESS_MESSAGE_ON_SHARE_QUOTE = 'Please check your email address for the shared quote PDF.';
  public static SUCCESS_MESSAGE_ON_SHARE_ORDER = 'Please check your email address for the shared invoice PDF.';
  public static SUCCESS_MESSAGE_ON_SHARE_TRACKING_LINK = 'Please check your email address for the shared tracking link.';
  public static SUCCESS_MESSAGE_ON_SHARE_PAYMENT = 'Please check your email address for the shared receipt PDF.';
  public static INFO_MESSAGE_ON_FINAL_PAYMENT = "You can't make the final payment until deposit paid.";
  public static PRODUCT_DOES_NOT_EXIST_MESSAGE = 'The product does not exist or not available on our website.';
  public static UNABLE_QUOTE_DETAIL_CHANGE = 'Quote is archived, please unarchive first.';
  public static UNABLE_QUOTE_STATUS_CHANGE_WITH_EXPIRED = 'This quote is expired, please reactivate it first.';
  public static QUOTE_QUANTITY_UPDATED = 'Quantity has been updated successfully';
  public static ERROR_MESSAGE_OF_DELIVERY_ADDRESS_ON_QUOTE = 'Please add delivery address.';
  public static ERROR_MESSAGE_OF_DELIVERY_ADDRESS_TRUCK_TYPE = 'Please select truck type.';
  public static SUCCESS_MESSAGE_UPDATE_PROJECT_REFERENCE = "Project reference has been updated successfully.";
  public static UNABLE_ORDER_DETAIL_CHANGE = 'The order is archived, please unarchive first.';
  public static QUANTITY_LENGTH_ERROR_MESSAGE = 'Quantity should not be greater than 9999.';
  public static GATHERCO_CREDIT_APPLIED_SUCCESS = 'Gatherco credit has been applied successfully.';
  public static GATHERCO_CREDIT_APPLIED_ERROR = 'Gatherco credit has not been applied.';
  public static SAMPLE_ORDER_ITEM_LIMIT_ERROR = 'You can place only 1 sample order per day with 6 items. Your sample order limit has been exceeded.';
  public static QUOTE_ORDER_DELIVERY_DETAIL_UPDATE = 'Delivery detail has been updated successfully.'
  public static QUOTE_ORDER_DELIVERY_ADDRESS_UPDATE = 'Delivery address has been updated successfully.'
  public static STREET_NO_REQUIRED = 'Please enter valid address street number is missing.'
  public static ACCOUNT_FILE_UPLOAD = 'Please upload image file only.'
  /*## General General/Success/Error Message ##*/

  /*## Confirmation Message ##*/
  public static CONFIRMATION_ADDRESS_DELETE_TITLE = 'Delete Address';
  public static CONFIRMATION_ADDRESS_DELETE_MESSAGE = 'This address will be deleted from the user account. Are you sure you want to delete this address?';
  public static CONFIRMATION_CART_PRODUCT_EXIST_TITLE = 'Alert';
  public static CONFIRMATION_CART_PRODUCT_EXIST_MESSAGE = 'This product is already exist in your quote. Would you like to increase the total QTY?';
  public static CONFIRMATION_CART_PRODUCT_DELETE_TITLE = 'Delete product';
  public static CONFIRMATION_CART_PRODUCT_DELETE_MESSAGE = 'This product will be deleted from the cart. Are you sure you want to delete this product?';
  public static CONFIRMATION_QUOTE_PRODUCT_DELETE_MESSAGE = 'This product will be deleted from the quote. Are you sure you want to delete this product?';
  public static CONFIRMATION_DELETE_QUOTE_TITLE = 'Delete Quote';
  public static CONFIRMATION_DELETE_QUOTE_MESSAGE = 'Delete quotes cannot be retrieved. Are you sure you want to proceed?';
  /*## Confirmation Message ##*/

  /*## Product Table Constant ##*/
  public static PRODUCT_STATUS_ACTIVE = 'A';
  public static PRODUCT_IS_SALE_YES = 'YES';
  public static PRODUCT_IS_NEW_YES = 'YES';
  public static PRODUCT_NEW_SALE_YES = 'YES';
  public static PRODUCT_HERO_FORMAT_SKU_YES = 'YES';
  public static PRODUCT_HERO_PIECE_YES = 'YES';
  public static PRODUCT_IS_AVAILABLE_YES = 'YES';
  public static PRODUCT_IS_FEATURE_PROFILE_YES = 'YES';
  public static PRODUCT_TYPE_SURFACE = 'S';
  public static PRODUCT_TYPE_SURFACE_ACCESSORY = 'SA';
  public static PRODUCT_TYPE_DECOR = 'D';
  public static PRODUCT_TYPE_DECOR_ACCESSORY = 'DA';
  public static PRODUCT_IS_DELETE_NO = 0;
  public static PRODUCT_MATERIAL = 'M';
  public static PRODUCT_FORMAT = 'F';
  public static PRODUCT_CATEGORY = 'C';
  public static PRODUCT_COLOR = 'C';
  public static PRODUCT_FILTER_PRICE = 'P';
  public static PRODUCT_FILTER_OTHER = 'O';
  public static PRODUCT_FAMILY = 'Family';
  public static PRODUCT_FILTER_LOW_LEAD_TIME_ID = "1";
  public static PRODUCT_FILTER_LOW_LEAD_TIME_VALUE = "Low Lead Time";
  public static PRODUCT_FILTER_NEW_PRODUCT_ID = "2";
  public static PRODUCT_FILTER_NEW_PRODUCT_VALUE = "New Product";
  public static PRODUCT_MAKER_MARK_SALE = 'SALE';
  public static PRODUCT_RESOURCE_DOWNLOAD = 'PR';
  public static PRODUCT_RESOURCE_DOWNLOAD_SORT = 'PSR';
  public static FILTER_PRODUCT_WITH_MATERIAL = 'material';
  public static FILTER_PRODUCT_WITH_FORMAT = 'format';
  public static FILTER_PRODUCT_WITH_COLOR = 'color';
  public static FILTER_PRODUCT_WITH_FAMILY = 'family';
  public static FILTER_PRODUCT_WITH_SALE = 'sale';
  public static FILTER_PRODUCT_WITH_LOW_LEAD_TIME = 'low-lead-time';
  public static FILTER_PRODUCT_WITH_NEW_PRODUCTS = 'new-products';
  public static PRODUCT_IMAGE_DOWNLOAD_SORT = 'PSI';
  public static PRODUCT_SEAMLESS_TEXTURE_IMAGE_DOWNLOAD = 'STI';
  public static OTHER_SEAMLESS_TEXTURE_IMAGE_DOWNLOAD = 'OSTI';
  public static PRODUCT_SINGLE_SEAMLESS_TEXTURE_IMAGE_DOWNLOAD = 'SSTI';
  public static PRODUCT_RECOMMENDED_INSTALLATION_DOWNLOAD = 'PIR';
  public static PRODUCT_PROJECT_IMAGERY = 'PRI';
  public static PRODUCT_ACCESSORY_IS_NOT_AVAILABLE_AT_THIS_MOMENT = 'Product accessory is not available at this moment';
  public static PRODUCT_NOT_FOUND = 'Product not found';
  /*## Product Table Constant ##*/

  /*## Quote Table Constant ##*/
  public static QUOTE_TYPE_QUOTE = "Q";
  public static QUOTE_TYPE_ORDER = "O";
  public static QUOTE_TYPE_SAMPLE_ORDER = "SO";
  public static QUOTE_IS_EXPIRED_NO = "NO";
  public static QUOTE_IS_EXPIRED_YES = "YES";
  public static QUOTE_IS_DELETE_NO = "0";
  public static QUOTE_STATUS_ACCEPT = "A";
  public static QUOTE_STATUS_REQUEST_DELIVERY_QUOTE = "SR";
  public static QUOTE_IS_ACCEPTED_YES = "Y";
  public static QUOTE_IS_ACCEPTED_NO = "N";
  public static QUOTE_IS_ARCHIVED_YES = "Y";
  public static QUOTE_IS_ARCHIVED_NO = "N";
  public static QUOTE_IN_SHIPPING_ZONE_YES = "Y";
  public static QUOTE_IN_SHIPPING_ZONE_NO = "N";
  public static IS_DISPLAY_ACCEPTED_QUOTE_YES = "Y";
  public static IS_DISPLAY_ACCEPTED_QUOTE_NO = "N";
  public static ACTIVITY_IS_QUOTE_EXPIRED_YES = "Y";
  public static ACTIVITY_IS_QUOTE_EXPIRED_NO = "N";
  public static QUOTE_IS_DRAFT = "Y";
  public static QUOTE_IS_NOT_DRAFT = "N";
  public static ORDER_TAB_ALL_SHORT = "all";
  /*## Quote Table Constant ##*/

  /*## Price Range Constant ##*/
  public static PRICE_RANGE_LEVEL_1 = '< $60 m2';
  public static PRICE_RANGE_LEVEL_2 = '$60 - $80 m2';
  public static PRICE_RANGE_LEVEL_3 = '$80 - $100 m2';
  public static PRICE_RANGE_LEVEL_4 = '$100 - $120 m2';
  public static PRICE_RANGE_LEVEL_5 = '$120 - $140 m2';
  public static PRICE_RANGE_LEVEL_6 = '$140 - $180 m2';
  public static PRICE_RANGE_LEVEL_7 = '$180 - $220 m2';
  public static PRICE_RANGE_LEVEL_8 = '$220+ m2';
  public static PRICE_RANGE_LEVEL_SALE_ID = '9';
  public static PRICE_RANGE_LEVEL_9 = 'Sale';
  public static PRICE_RANGE_LABEL_LEVEL_1 = '< $60 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_2 = '$60 - $80 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_3 = '$80 - $100 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_4 = '$100 - $120 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_5 = '$120 - $140 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_6 = '$140 - $180 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_7 = '$180 - $220 m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_8 = '$220+ m<sup>2</sup>';
  public static PRICE_RANGE_LABEL_LEVEL_9 = 'Sale';
  /*## Price Range Constant ##*/

  /*## Image Size Declaration Constant ##*/
  public static IMAGE_SIZE_292_X_532 = '?tr=w-292,h-532';
  public static IMAGE_SIZE_373_X_531 = '?tr=w-373,h-531';
  public static IMAGE_SIZE_332_X_272 = '?tr=w-332,h-272';
  public static IMAGE_SIZE_332_X_253 = '?tr=w-332,h-253';
  public static IMAGE_SIZE_315_X_293 = '?tr=w-315,h-293';
  public static IMAGE_SIZE_315_X_232 = '?tr=w-315,h-232';
  public static IMAGE_SIZE_1500_X_1500 = '?tr=w-1500,h-1500';
  public static IMAGE_SIZE_877_X_877 = '?tr=w-877,h-877';
  public static IMAGE_SIZE_877_X_454 = '?tr=w-877,h-454';
  public static IMAGE_SIZE_740_X_740 = '?tr=w-740,h-740';
  public static IMAGE_SIZE_601_X_601 = '?tr=w-601,h-601';
  public static IMAGE_SIZE_1200_X_630 = '?tr=w-1200,h-630';
  public static IMAGE_SIZE_590_X_590 = '?tr=w-590,h-590';
  public static IMAGE_SIZE_513_X_513 = '?tr=w-513,h-513';
  public static IMAGE_SIZE_463_X_463 = '?tr=w-463,h-463';
  public static IMAGE_SIZE_392_X_392 = '?tr=w-392,h-392';
  public static IMAGE_SIZE_391_X_810 = '?tr=w-391,h-810';
  public static IMAGE_SIZE_337_X_337 = '?tr=w-337,h-337';
  public static IMAGE_SIZE_291_X_291 = '?tr=w-291,h-291';
  public static IMAGE_SIZE_251_X_205 = '?tr=w-251,h-205';
  public static IMAGE_SIZE_249_X_133 = '?tr=w-249,h-133';
  public static IMAGE_SIZE_322_X_120 = '?tr=w-322,h-120';
  public static IMAGE_SIZE_125_X_125 = '?tr=w-125,h-125';
  public static IMAGE_SIZE_96_X_96 = '?tr=w-96,h-96';
  public static IMAGE_SIZE_90_X_90 = '?tr=w-90,h-90';
  public static IMAGE_SIZE_79_X_79 = '?tr=w-79,h-79';
  public static IMAGE_SIZE_74_X_74 = '?tr=w-74,h-74';
  public static IMAGE_SIZE_69_X_69 = '?tr=w-69,h-69';
  public static IMAGE_SIZE_68_X_68 = '?tr=w-68,h-68';
  public static IMAGE_SIZE_65_X_65 = '?tr=w-65,h-65';
  public static IMAGE_SIZE_62_X_62 = '?tr=w-62,h-62';
  public static IMAGE_SIZE_58_X_58 = '?tr=w-58,h-58';
  public static IMAGE_SIZE_54_X_54 = '?tr=w-54,h-54';
  public static IMAGE_SIZE_50_X_50 = '?tr=w-50,h-50';
  public static IMAGE_SIZE_40_X_40 = '?tr=w-40,h-40';
  public static IMAGE_SIZE_46_X_46 = '?tr=w-46,h-46';
  public static IMAGE_SIZE_38_X_38 = '?tr=w-38,h-38';
  public static IMAGE_SIZE_37_X_37 = '?tr=w-37,h-37';
  public static IMAGE_SIZE_34_X_34 = '?tr=w-34,h-34';
  public static IMAGE_SIZE_30_X_30 = '?tr=w-30,h-30';
  public static IMAGE_SIZE_323_X_323 = '?tr=w-323,h-323';
  public static IMAGE_SIZE_542_X_1400 = '?tr=w-1400,h-542';
  public static IMAGE_SIZE_878_X_454 = '?tr=w-878,h-454';
  public static IMAGE_SIZE_HEIGHT_500 = '?tr=h-500';
  public static IMAGE_SIZE_HEIGHT_1000 = '?tr=h-1000';
  public static IMAGE_SIZE_500_X_500 = '?tr=w-500,h-500';
  public static IMAGE_SIZE_WIDTH_323 = '?tr=w-323';
  public static IMAGE_SIZE_WIDTH_1500 = '?tr=w-1500';
  public static IMAGE_SIZE_WIDTH_740 = '?tr=w-740';
  public static IMAGE_SIZE_WIDTH_1400 = '?tr=w-1400';
  public static IMAGE_SIZE_WIDTH_251 = '?tr=w-251';
  public static IMAGE_SIZE_245_X_245 = '?tr=w-245,h-245';
  public static IMAGE_SIZE_147_X_147 = '?tr=w-147,h-147';
  public static IMAGE_SIZE_917_X_917 = '?tr=w-917,h-917';
  public static IMAGE_SIZE_952_X_839 = '?tr=w-952,h-839';
  public static IMAGE_SIZE_464_X_464 = '?tr=w-464,h-464';
  public static IMAGE_SIZE_392_X_939 = '?tr=w-392,h-939';
  public static IMAGE_SIZE_595_X_395 = '?tr=w-595,h-395';
  public static IMAGE_SIZE_88_X_88 = '?tr=w-88,h-88';
  public static IMAGE_SIZE_620_X_391 = '?tr=w-620,h-391';
  public static IMAGE_SIZE_1240_X_800 = '?tr=w-1240,h-800';
  /*## Image Size Declaration Constant ##*/

  /*## Quote Status Constant  ##*/
  public static QUOTE_FEEDBACK_FUILLNAME = 'Feedback';
  public static QUOTE_ORDER_RECEIVED = 'OR';
  public static QUOTE_PACKING = 'AAW';
  public static QUOTE_IN_TRANSIT = 'IT';
  public static QUOTE_SAMPLE_IN_TRANSIT = 'OIW';
  public static QUOTE_COMPLETED = 'C';
  public static ORDER_CANCELLED = 'OCAN';
  public static QUOTE_DELIVERED = 'D';
  public static QUOTE_FOLLOWUP = 'FU';
  public static QUOTE_SAMPLE_PACKAGED_SHORT = 'PC';
  public static INPUT_PLACEHOLDER = 'Search';
  public static QUOTE_CREATED_SHORT = 'QC';
  public static QUOTE_ACCEPTED_SHORT = 'QA';
  public static QUOTE_DELIVERY_ADDRESS_REQUIRED_SHORT = 'DAR';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_REQUIRED_SHORT = 'MDQR';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_PENDING_SHORT = 'MDQP';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_ADDED_SHORT = 'MDQAD';
  public static QUOTE_MANUAL_DELIVERY_QUOTE_APPLIED_SHORT = 'MDQAP';
  // public static QUOTE_FOLLOW_UP_SHORT = 'FU';
  public static QUOTE_DEPOSIT_PAYMENT_DUE_SHORT = 'DPDU';
  public static QUOTE_DEPOSIT_PAID_SHORT = 'DPD';
  public static QUOTE_EXPIRED_SHORT = 'EXP';
  public static QUOTE_ARCHIVED_SHORT = 'QARCH';
  public static QUOTE_AUTO_DELIVERY_QUOTE_APPLIED_SHORT = 'ADQAP';
  public static QUOTE_ORDER_CREATED_SHORT = 'OC';
  public static QUOTE_CLIENT_ORGANIZED_ORDER_COLLECTION_SHORT = 'COOC';
  public static QUOTE_DEPOSIT_PAYMENT_PROCESSING_SHORT = 'DPPROC';
  public static QUOTE_FINAL_PAYMENT_PROCESSING_SHORT = 'FPPROC';
  public static QUOTE_FULL_PAYMENT_PROCESSING_SHORT = 'FUPPROC';
  public static QUOTE_EXPIRY_IMMANENT_SHORT = 'QEI';
  public static QUOTE_DEPOSIT_EXPIRY_IMMANENT_SHORT = 'DEI';
  public static DELIVERY_ADDRESS_REQUIRED = 'Delivery Address Required';
  public static MANUAL_DELIVERY_QUOTE_REQUIRED = 'Manual Delivery Quote Required';
  public static MANUAL_DELIVERY_QUOTE_PENDING = 'Manual Delivery Quote Pending';
  public static MANUAL_DELIVERY_QUOTE_ADDED = 'Manual Delivery Quote Added';
  public static MANUAL_DELIVERY_QUOTE_APPLIED = 'Manual Delivery Quote Applied';
  public static AUTO_DELIVERY_QUOTE_APPLIED = 'Auto Delivery Quote Applied';
  public static GOODS_DISPATCHED_UNSPECIFIED = 'Goods Despatch Unspecified';
  public static GOODS_DISPATCHED_PENDING = 'Goods Despatch Pending';
  public static GOODS_DISPATCHED_CONFIRMED = 'Goods Despatch Confirmed';
  public static FOLLOW_UP = 'Follow Up';
  public static CLIENT_ORGANIZED_ORDER_COLLECTION = 'Client Organized Order Collection';
  public static DELAYED_IN_TRANSIT = 'Delayed In Transit';
  public static QUOTE_STATUS_CANCELLED = 'QCAN';
  public static QUOTE_REACTIVATED_SHORT = 'QRA';

  /*## Quote Status Constant  ##*/

  /*## Order status Constant ##*/
  public static ORDER_RECEIVED_SHORT = 'OR';
  public static ORDER_IN_PRODUCTION_SHORT = 'IP';
  public static ORDER_IN_TRANSIT_SHORT = 'IT';
  public static ORDER_CLEARED_CUSTOMS_SHORT = 'CC';
  public static ORDER_PACKING_SHORT = 'AAW';
  public static ORDER_FINAL_PAYMENT_PENDING_SHORT = 'FPP';
  public static ORDER_FINAL_PAYMENT_PAID_SHORT = 'FPPD';
  public static ORDER_FULL_PAYMENT_PAID_SHORT = 'FUPP';
  public static ORDER_GOODS_DISPATCHED_UNSPECIFIED_SHORT = 'GDUNS';
  public static ORDER_GOODS_DISPATCHED_OUT_TO_TENDER_SHORT = 'GDOTT';
  public static ORDER_GOODS_DISPATCHED_SHORT = 'GD';
  public static ORDER_GOODS_DISPATCHED_ACCEPTED_SHORT = 'GDA';
  public static ORDER_GOODS_DISPATCHED_DECLINED_SHORT = 'GDD';
  public static ORDER_GOODS_DISPATCHED_CLIENT_ORGANIZED_SHORT = 'GDCO';
  public static ORDER_GOODS_DISPATCHED_DELIVERD_SHORT = 'GDDE';
  public static ORDER_COMPLETED_SHORT = 'C';
  public static ORDER_FOLLOW_UP_SHORT = 'FU';
  public static ORDER_DELIVERY_SEQUENCE = 'DSEQ';


  public static QUOTE_ORDER_RECEIVED_SHORT = 'OR';
  public static QUOTE_IN_PRODUCTION_SHORT = 'IP';
  public static QUOTE_GOODS_DISPATCHED_SHORT = 'GD';
  public static QUOTE_GOODS_DISPATCHED_DELIVERD_SHORT = 'GDDE';
  public static QUOTE_CLEARED_CUSTOMS_SHORT = 'CC';
  public static QUOTE_PACKING_SHORT = 'AAW';
  public static QUOTE_ORDER_WAREHOUSE_ARRIVAL_DATE_STATUS = 'AAW';
  public static QUOTE_FINAL_PAYMENT_PENDING_SHORT = 'FPP';
  public static QUOTE_FINAL_PAYMENT_PAID_SHORT = 'FPPD';
  public static QUOTE_FULL_PAYMENT_PAID_SHORT = 'FUPP';
  public static QUOTE_IN_TRANSIT_SHORT = 'IT';
  public static QUOTE_DELAYED_IN_TRANSIT_SHORT = 'DIT';
  public static QUOTE_COMPLETED_SHORT = 'C';
  public static ORDER_STATUS_PAYMENT_OVERDUE = 'PO';
  public static ORDER_STATUS_PAYMENT_DELIVERY_APPROVAL = 'PDA';
  public static ORDER_STATUS_AWAITING_FINAL_PAYMENT = 'AFP';
  public static ORDER_STATUS_PAYMENT_COMPLETE = 'PCT';
  public static ORDER_STATUS_COMPLETED = 'C';
  public static ORDER_STATUS_CANCELLED = 'CAN';
  /*## Order Status Constant ##*/

  /*## Carrier Status Id Constant ##*/
  public static CARRIER_STATUS_UNSPECIFIED_ID = 1;
  public static CARRIER_STATUS_OUT_TO_TENDER_ID = 2;
  public static CARRIER_STATUS_DESPATCHED_ID = 3;
  public static CARRIER_STATUS_ACCEPTED_ID = 4;
  public static CARRIER_STATUS_DECLINED_ID = 5;
  public static CARRIER_STATUS_CLIENT_ORGANIZED_ID = 6;
  public static CARRIER_STATUS_DELIVERED_ID = 7;
  /*## Carrier Status Id Constant ##*/

  /*## Samples Status Constant ##*/
  public static GENERAL_USER_SAMPLE_ORDER_STATUS_ARRAY_VIEW_SAMPLES = [
    {
      name: 'Logged',
      class: 'received',
      status: ['OR'],
      label_class: 'recived-flag',
      model_header: 'Order Received'
    },
    {
      name: 'Packaged',
      class: 'packing',
      status: ['PC'],
      label_class: 'packaging-flag',
      model_header: 'Order Packaged'
    },
    {
      name: 'On its way',
      class: 'transit',
      status: ['OIW'],
      label_class: 'transit-flag',
      model_header: 'Order On Its Way'
    },
    {
      name: 'Delivered',
      class: 'delivered',
      status: ['D'],
      label_class: 'completed-flag',
      model_header: 'Order Delivered'
    },
    {
      name: 'Follow Up',
      class: 'follow-up',
      status: ['FU'],
      label_class: 'follow-up-flag',
      model_header: 'Order Delivered'
    }
  ];
  public static TRADE_SAMPLE_ORDER_STATUS_ARRAY = [
    {
      name: 'Samples Requested',
      class: 'received-sample',
      status: ['OR'],
      label_class: 'recived-flag-sample',
      disable_class: 'sample-request-disable',
      color: '#D51ED2',
      background: '#D51ED2'
    },
    {
      name: 'Packaged',
      class: 'packing',
      status: ['PC'],
      label_class: 'packaging-flag',
      disable_class: 'package-disable',
      color: '#362E9B',
      background: '#362E9B'
    },
    {
      name: 'Despatched',
      class: 'transit',
      status: ['OIW'],
      label_class: 'transit-flag',
      email_template_key: ['sample-dispatched'],
      disable_class: 'despatched-disable',
      color: '#B98000',
      background: '#B98000'
    },
    {
      name: 'Delivered',
      class: 'complete',
      status: ['D'],
      label_class: 'completed-flag',
      email_template_key: ['sample-order-complete'],
      disable_class: 'delivered-disable',
      color: '#222324',
      background: '#222324'
    },
    {
      name: 'Follow Up', class: 'followup', status: ['FU'], label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      color: '#6D709A',
      background: '#6D709A'
    },
    {
      name: 'Complete', class: 'complete', status: ['C'], label_class: 'complete-flag',
      disable_class: 'complete-disable',
      color: '#222324',
      background: '#222324'
    }
  ];
  /*## Samples Status Constant ##*/

  /*## Quote Status Constant ##*/
  public static QUOTE_STATUS_ARRAY = [
    {
      name: 'Quote Created',
      formatted_name: 'Quote<br>Created',
      class: 'quote',
      status: ['QC'],
      label_class: 'quote-flag',
      disable_class: 'quote-disable',
      color: '#9A5FA8',
      background: '#9A5FA8'
    },
    {
      name: 'Auto Delivery Quote Applied',
      formatted_name: 'Auto Delivery<br>Quote Applied',
      color: '#499D63',
      class: 'auto-delivery-quote-applied',
      status: ['ADQAP'],
      label_class: 'auto-delivery-quote-applied-flag',
      disable_class: 'applied-disable',
      background: '#499D63'
    },
    {
      name: 'Delivery Address Required',
      class: 'delivery-address-required',
      status: ['DAR'],
      label_class: 'delivery-address-required-flag',
      disable_class: 'delivery-address-required-disable',
      background: '#D42A3B',
      color: '#D42A3B',
      formatted_name: 'Delivery Address<br>Required',
    },
    {
      name: 'Manual Delivery Quote Required',
      class: 'manual-delivery-quote-required',
      status: ['MDQR'],
      label_class: 'manual-delivery-quote-required-flag',
      disable_class: 'pending-disable',
      background: '#D42A3B',
      color: '#D42A3B',
      formatted_name: 'Manual Delivery<br>Quote Required',
    },
    {
      name: 'Manual Delivery Quote Pending',
      class: 'manual-delivery-quote-pending',
      status: ['MDQP'],
      label_class: 'manual-delivery-quote-charge-pending-flag',
      disable_class: 'pending-disable',
      background: '#D42A3B',
      color: '#D42A3B',
      formatted_name: 'Manual Delivery<br>Quote Pending',
    },
    {
      name: 'Manual Delivery Quote Added',
      class: 'manual-delivery-quote-added',
      status: ['MDQAD'],
      label_class: 'manual-delivery-quote-added-flag',
      disable_class: 'added-disable',
      background: '#6D709A',
      color: '#6D709A',
      formatted_name: 'Manual Delivery<br>Quote Applied',
    },
    {
      name: 'Manual Delivery Quote Applied',
      color: '#499D63',
      class: 'manual-delivery-quote-applied',
      status: ['MDQAP'],
      label_class: 'manual-delivery-quote-applied-flag',
      disable_class: 'applied-disable',
      background: '#499D63',
      formatted_name: 'Manual Delivery<br>Quote Applied',
    },
    {
      name: 'Follow Up',
      formatted_name: 'Follow<br>Up',
      color: '#6D709A',
      class: 'followup',
      status: ['FU'],
      label_class: 'followup-flag',
      disable_class: 'follow-up-disable',
      background: '#6D709A'
    },
    {
      name: 'Quote Accepted',
      formatted_name: 'Quote<br>Accepted',
      color: '#362E9B',
      class: 'quote-accepted',
      status: ['QA'],
      label_class: 'quote-accepted-flag',
      disable_class: 'quote-accepted-disable',
      background: '#362E9B'
    },
    {
      name: 'Deposit Payment Due',
      formatted_name: 'Deposit<br>Payment Due',
      class: 'deposit-payment-due',
      status: ['DPDU'],
      label_class: 'pending-flag',
      disable_class: 'deposit-pending-disable',
      background: '#3D95ED',
      color: '#3D95ED'
    },
    {
      name: 'Expired',
      formatted_name: 'Expired',
      class: 'expired',
      color: '#D42A3B',
      status: ['EXP'],
      label_class: 'expired-flag',
      disable_class: 'expired-disable',
      background: '#D42A3B'
    },
    {
      name: 'Archived',
      color: '#919192',
      status: ['QARCH'],
      label_class: 'archive-flag',
      background: 'none'
    }
  ];
  /*## Quote Status Constant ##*/

  /*## Order Status Constant ##*/
  public static ORDER_STATUS_ARRAY = [
    {
      name: 'Order<br>Received',
      class: 'received',
      status: ['OR'],
      label_class: 'recived-flag',
      email_template_key: ['order-received', 'deposit-paid'],
      disable_class: 'order-received-disable'
    },
    {
      name: 'In<br>Production',
      color: '#4EBAB6',
      status: ['IP'],
      label_class: 'production-flag',
      disable_class: 'in-production-disable'
    },
    {
      name: 'In<br>Transit',
      class: 'transit',
      status: ['IT'],
      label_class: 'transit-flag',
      email_template_key: ['in-transit'],
      disable_class: 'in-transit-disable'
    },
    {
      name: 'Customs<br>Held',
      color: '#499D63',
      status: ['CC'],
      label_class: 'cleared-flag',
      disable_class: 'custom-held-disable'
    },
    {
      name: 'Available At<br>Warehouse',
      class: 'packing',
      status: ['AAW'],
      label_class: 'packaging-flag',
      email_template_key: ['stock-landed'],
      disable_class: 'available-at-warehouse-disable'
    },
    {
      name: 'Final Payment<br>Outstanding',
      color: '#3D95ED',
      status: ['FPP'],
      label_class: 'final-payment-flag',
      email_template_key: ['final-payment-reminder'],
      disable_class: 'final-payment-pending-disable'
    },
    {
      name: 'Final Payment<br>Paid',
      class: 'received',
      status: ['FPPD', 'FUPP'],
      label_class: 'final-payment-paid-flag',
      email_template_key: ['nominate-delivery-date'],
      disable_class: 'final-payment-paid-disable'
    },
    {
      name: 'Nominate Delivery<br>Date',
      color: '#D42A3B',
      status: ['GDUNS'],
      class: 'goods-despatch-unspecified-flag',
      label_class: 'goods-despatch-unspecified-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-unspecified-disable'
    },
    {
      name: 'Awaiting Delivery<br>Booking',
      color: '#D42A3B',
      status: ['GDUNS'],
      class: 'goods-despatch-unspecified-flag',
      label_class: 'goods-despatch-unspecified-flag-specified-date',
      email_template_key: [],
      disable_class: 'goods-despatched-unspecified-disable'
    },
    {
      name: 'Awaiting Delivery<br>Booking',
      color: '#FF7600',
      status: ['GDOTT'],
      class: 'goods-despatch-out-to-tender-flag',
      label_class: 'goods-despatch-out-to-tender-flag',
      email_template_key: ['trade-user-carrier-added'],
      disable_class: 'goods-despatched-pending-disable'
    },
    {
      name: 'Scheduled for<br>Delivery',
      color: '#499D63',
      status: ['GDA'],
      class: 'goods-despatch-accepted-flag',
      label_class: 'goods-despatch-accepted-flag',
      disable_class: 'goods-despatched-accepted-disable'
    },
    {
      name: 'Awaiting Delivery<br>Booking',
      color: '#499D63',
      status: ['GDD'],
      class: 'goods-despatch-declined-flag',
      label_class: 'goods-despatch-declined-flag',
      disable_class: 'goods-despatched-declined-disable'
    },
    {
      name: 'Goods Despatched',
      color: '#499D63',
      status: ['GD'],
      class: 'goods-despatched-flag',
      label_class: 'goods-despatched-flag',
      email_template_key: ['order-dispatched'],
      disable_class: 'goods-despatched-confirmed-disable'
    },
    {
      name: 'Goods Despatch<br>Delivered',
      color: '#499D63',
      status: ['GDDE'],
      class: 'goods-despatch-delivered-flag',
      label_class: 'goods-despatched-delivered-flag',
      disable_class: 'goods-despatched-delivered-confirmed-disable'
    },
    {
      name: 'Goods Despatch<br>Client Organized',
      color: '#499D63',
      status: ['GDCO'],
      class: 'goods-despatched-client-organized-flag',
      label_class: 'goods-despatched-client-organized-flag',
      email_template_key: [],
      disable_class: 'goods-despatched-client-organized-disable'
    },
    {
      name: 'Complete',
      class: 'complete',
      status: ['C'],
      label_class: 'completed-flag',
      email_template_key: ['order-complete'],
      disable_class: 'complete-disable'
    }
  ];
  /*## Order Status Constant ##*/

  public static CONTACT_US_CONSTANTS = {
    SUCCESSFULLY: 'Thank you for filling out your information!',
    ERROR: 'Contact after some time',
  };

  public static ORDER_DELIVERY_DATE_FORM = {
    MAIN_HEADING: 'Add Stock Required Date',
    LABEL: 'Stock Required Date',
    REQUIRED_ERROR_MESSAGE: 'Please enter stock required date',
    EDIT_MAIN_HEADING: 'Edit Stock Required Date',
  }

  public static ORDER_WAREHOUSE_ARRIVAL_DATE_FORM = {
    MAIN_HEADING: 'Add Warehouse Arrival Date',
    LABEL: 'Warehouse Arrival Date',
    REQUIRED_ERROR_MESSAGE: 'Please enter warehouse arrival date',
    EDIT_MAIN_HEADING: 'Edit Warehouse Arrival Date',
  }

  public static DELIVERY_DATE_UPDATE_ORDER = {
    ADD: "Nominated Delivery date added successfully.",
    UPDATE: "Nominated Delivery date updated successfully.",
  }

  public static STOCK_REQUIRE_DATE_UPDATE_ORDER = {
    ADD: "Stock required date has been added successfully.",
    UPDATE: "Stock required date has been updated successfully.",
  }

  public static WAREHOUSE_ARRIVAL_DATE_MESSAGES = {
    ADD: "Warehouse arrival date has been added successfully.",
    UPDATE: "Warehouse arrival date has been updated successfully.",
  }

  public static FAQ_LISTS = {
    ARTICLE_PAGE: '1',
    HELP_CENTER_PAGE: '2',
    THE_GATHER_COMMITMENT_PAGE: '3',
    SAMPLES_PAGE: '4',
    CONTACTS_US_PAGE: '5',
    ACCOUNT_ORDER_PAGE: '6',
    ACCOUNT_QUOTE_PAGE: '7',
    ACCOUNT_GATHER_COMMITMENT_PAGE: '8',
    ACCOUNT_SAMPLES_PAGE: '9',
    DASHBOARD_PAGE: '10'
  }

  public static HELP_CENTER = {
    HELP_FULL_SUCCESSFULLY: "Thank you for response"
  }

  public static GROUT_COLOUR_TYPES = {
    TYPE_FG: "FG",
    TYPE_WJ: "WJ"
  }

  public static VIEW_QUOTE_CONSTANTS = {
    //#region   SINGLE CONSTANTS
    NEW_CREDIT_EARN: `Calculated on the sub-total, minus any credit applied to this quote.`,
    CREDIT_APPLY_ERROR: `Credit applied may only amount to a maximum 49% of total order value.`,
    CREDIT_APPLY_SLIDER_DESCRIPTION: `Shift the slider below to assign part or all of your available credit to this quote. The credit applied will <u><b>not</b></u> be deducted from the initial deposit sum, but rather - will be subtracted from your final payment.`,

    ORDER_DETAIL_AND_DELIVERY: `This platform operates our automated trucking calculator. For many destinations, completing the below form instantly generates an applicable delivery fee.`,
    ORDER_DETAIL_AND_DELIVERY_CONFIRMED: `Now that delivery has been confirmed, no further edits can be applied`,
    WARNING_AFTER_QUOTE_ACCEPTED: `This quote has been accepted and can no longer be further edited.`,
    MANUAL_DELIVERY_CHARGE_PENDING_WARNING: `No edits can made to items in this quote whilst the manual delivery quote is pending.`,
    MANUAL_DELIVERY_CHARGE_APPLIED_WARNING: `Further adjustment of items or quantities will require that the applied delivery charge be re-quoted.`,
    ORDER_DETAIL_CANNOT_EDIT: `The below details were confirmed at the quoting stage and cannot be further edited.`,
    PRODUCT_NO_LONGER_AVAILABLE_WARNING: `We apologise, but select Items listed below are no longer available. Please remove the highlighted products to proceed with your order.`,
    //#endregion

    // EXPIRE QUOTE
    EXPIRE_QUOTE: {
      TOP_DESCRIPTION_FOR_REACTIVE: ` You can reactivate this quote, however, the availability of certain
    stock and sizes as well as prices may have changed.`
    },

    //TERM & CONDITIONS
    TERMS_CONDITIONS: {
      FOOTER: `Acceptance:<br>You willing accept our full set of Terms when:<br>
      (a) you provide us with a completed account application form or apply for an online account;<br>
      (b) you submit an Order (online or otherwise);<br>
      (c) you accept Delivery of, or any part of ,any Goods pursuant to an Order; or<br>
      (d) you make Payment ,or partial Payment, for any Goods supplied by us,<br>
      whichever occurs first.`
    },


    //#region TOP DESCRIPTION
    TOP_DESCRIPTION: {
      DEPOSIT_PAYMENT__IS_REQUIRED: `Delivery on this order must be calculated manually, and will be billed on
      a<br /> separate invoice. We will be in contact shortly.`,
      QUOTE_CREATED_SHORT_IN_SHIPPING_ZONE_NO: `Due to address delivery a <b>Request delivery quote</b>
      must be made. The delivery cost will then be added to your order prior to deposit payment. Response will be within 24 - 48 hrs.`,
      QUOTE_MANUAL_DELIVERY_QUOTE_ADDED_SHORT: `Delivery cost has been calculated and added below. Please approved<br />
      delivery cost to create
      final order.`
    },

    //#endregion

    //#region FOOTER DESCRIPTION
    FOOTER_DESCRIPTION: {
      OUT_OF_DELIVERY_ZONE: ` Transport must be calculated manually, and will be billed on a separate
      invoice. We will be in
      contact shortly.`,
      IN_SHIPPING_ZONE: `Delivery is
      calculated
      automatically,
      based on pre-determined
      parameters. Please <a href="/contact-us">make contact</a> for further information.`,
      QUOTE_CREATED_SHORT_IN_SHIPPING_ZONE: ` Delivery must be calculated manually, and will be added to your order
      prior to deposit payment.
      Once you request a delivery quote, it will become an Order. Response
      will be within 24 - 48 hrs.`
    }
    //#endregion

  }

  public static VIEW_ORDER = {
    //#region SINGLE
    ORDER_DETAIL_AND_DELIVERY: `Below details have been confirmed, and cannot be altered at this stage.`,
    CREDIT_APPLY_SLIDER_DESCRIPTION: `This is calculated on the sub-total minus the credit applied to
    this order.`,
    //#endregion

    //#region TOP DESCRIPTION
    TOP_DESCRIPTION: {
      ORDER_STATUS_PAYMENT_DELIVERY_APPROVAL: `Keep track of your purchases and see exactly where they are in our production pipeline.`,
      ORDER_STATUS_PAYMENT_DELIVERY_APPROVAL_WITH_N0_SHIPPING_COST: `Delivery on this order must be calculated manually, and will be billed on a<br /> separate invoice.
      We will be in contact shortly.`,
      ORDER_STATUS_PAYMENT_DELIVERY_APPROVAL_WITH_SHIPPING_COST: ` Delivery cost has been calculated and added below. Please approved delivery cost to create final
      order.`,
    },
    //#endregion

    //TERM & CONDITIONS
    TERMS_CONDITIONS: {
      FOOTER: `Acceptance:<br>You willing accept our full set of Terms when:<br>
      (a) you provide us with a completed account application form or apply for an online account;<br>
      (b) you submit an Order (online or otherwise);<br>
      (c) you accept Delivery of, or any part of ,any Goods pursuant to an Order; or<br>
      (d) you make Payment ,or partial Payment, for any Goods supplied by us,<br>
      whichever occurs first.`
    },

    FOOTER_DESCRIPTION: {
      IS_OUT_OF_ZONE: `Transport must be calculated manually, and will be billed on a separate invoice. We will be in contact shortly.`,
      IS_IN_ZONE: `Transport must be calculated manually, and will be billed on a separate invoice. We will be in contact shortly.`,
      COMMON_DESCRIPTION: `Delivery is calculated automatically, based on pre-determined
      parameters. Please <a href="/contact-us">make contact</a>
      for further information.`
    },
    DELIVERY_INSTRUCTION_INSERT_SUCCESSFULLY: 'Delivery instruction has been added successfully.',
    DELIVERY_INSTRUCTION_UPDATE_SUCCESSFULLY: 'Delivery instruction has been updated successfully.',
    SITE_CONTACT_EMAIL_INSERT_SUCCESSFULLY: 'Site contact email has been added successfully.',
    SITE_CONTACT_EMAIL_UPDATE_SUCCESSFULLY: 'Site contact email has been updated successfully.',
    SITE_CONTACT_NUMBER_INSERT_SUCCESSFULLY: 'Site contact number has been added successfully.',
    SITE_CONTACT_NUMBER_UPDATE_SUCCESSFULLY: 'Site contact number has been updated successfully.',
    SITE_CONTACT_NAME_INSERT_SUCCESSFULLY: 'Site contact name has been added successfully.',
    SITE_CONTACT_NAME_UPDATE_SUCCESSFULLY: 'Site contact name has been updated successfully.'
  }

  // public static BPAY_INFO_DESCRIPTION = `Once the payment has been successfully made, kindly forward the payment receipt / screenshot to <a href="mailto:michael@gatherco.com.au">michael@gatherco.com.au</a>. This will ensure prompt processing of your payment.`;
  public static BPAY_INFO_DESCRIPTION = `To ensure this transaction is promptly processed, please be sure to upload your payment remittence via the "Okay, I' ve Paid" button below.`;

  public static RESOURCE_CATEGORIES = {
    CATEGORY_TIPS_FOR_INSTALLATION: "Tips for Installation",
    CATEGORY_SUPPLY_PARTNERS: "Supply Partners",
    CATEGORY_SEALING_PROCEDURES: "Sealing Procedures",
    CATEGORY_MAINTENANCE_GUIDE: "Maintenance Guide",
    CATEGORY_SEALING_PROCEDURE: "Sealing Procedure",
  }

  public static SAMPLE_ORDER_MODEL = {
    FEEDBACK_NOT_APPLICABLE: 'You have no access to for feedback for this sample order due to order is created by'
  }

  public static APPLY_GATHER_COMMITMENT = {
    TOP_DESCRIPTION: 'Shift the slider below to assign part or all of your available credit to this quote. The credit applied will <u><b>not</b></u> be deducted from the initial deposit sum, but rather - will be subtracted from your final payment.',
    DESIGNATE_OR_DONATE: `Credits earned by your team can be designated to projects at any stage of the online quoting process.
  You also have the option to donate a portion of your credits to our Tiles for Smiles initiative - it’s all about goods for the greater good.`
  }

  public static SUCCESS_MESSAGE_ADD_QUANTITY_FOR_ACCESSORIES(profileName: String) {
    return `The product accessories ${profileName} already exist in your quote. Would you like to increase the total QTY?`
  };

  public static GATHERCO = {
    ABN_NUMBER: '65 601 533 144',
    ADDRESS: 'Sydney - AUSTRALIA',
    CONTACT_NUMBER: '1800 428 437',
    EMAIL_ADDRESS: 'hi@gatherco.com.au',
    INFO: '@gathercoaustralia',
  }

  public static WAREHOUSE_COLLECTION_ADDRESS = {
    ADDRESS_LINE_1: '89 Fairey Road',
    ADDRESS_LINE_2: '',
    SUBURB: 'South Windsor',
    TOWN_CITY: 'Hawkesbury City Council',
    STATE_REGION: 'NSW',
    POST_CODE: '2756',
    COUNTRY: 'Australia',
  }

  public static PRODUCT_ADDED_SUCCESS_MESSAGE(moduleName: String) {
    return `Product has been added successfully to the ${moduleName}`
  };

  public static QUOTE_ORDER_MESSAGE_CONSTANTS = {
    PRODUCT_REMOVE: "Product has been removed successfully",
    PRODUCT_RESTRICTION_FOR_LAST_PRODUCT: "You cannot delete the last product from sample order.",
    QUOTE_PRODUCT_RESTRICTION_FOR_LAST_PRODUCT: "You cannot delete the last product from quote.",
    QUOTE_ORDER_REMOVE: "Quote/Order deleted successfully.",
    QUOTE_REMOVE: "Quote deleted successfully.",
    ORDER_REMOVE: "Order deleted successfully.",
  }

  public static CONSUMABLE_CALCULATOR_CONSTANTS = {
    TYPE_ADHESIVE: `Laticrete 335 Adhesive`,
    TYPE_DEFENDER: `Actichem CSD Defender`,
    TYPE_GOLD: `Actichem Solv Sealer Gold`,
    // ADDED_TO_QUOTE_SUCCESS_MESSAGE: `Consumable calculator has been added to the quote successfully.`,
    ADDED_TO_QUOTE_SUCCESS_MESSAGE: `Selected item successfully added to your quote.`,
    // ADDED_TO_QUOTE_ERROR_MESSAGE: `Consumable calculator has not been added to the quote.`,
    ADDED_TO_QUOTE_ERROR_MESSAGE: `Selected item successfully not added to your quote`,
    // REMOVED_FROM_QUOTE_SUCCESS_MESSAGE: `Consumable calculator has been removed from the quote successfully.`,
    REMOVED_FROM_QUOTE_SUCCESS_MESSAGE: `Selected item successfully removed from your quote.`,
    // REMOVED_FROM_QUOTE_ERROR_MESSAGE: `Consumable calculator has not been removed from the quote.`,
    REMOVED_FROM_QUOTE_ERROR_MESSAGE: `Selected item successfully not removed from your quote.`,
  }

  public static ADD_CONSUMABLE_CALCULATOR_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want add this consumable calculator?',
  }

  public static REMOVE_CONSUMABLE_CALCULATOR_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want removed this consumable calculator?',
  }

  public static ADD_PRODUCT_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want add this product?',
  }

  public static UPDATE_PRODUCT_QUANTITY_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want update the quantity of this product?',
  }

  public static UPDATE_PRODUCT_ADDRESS_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want update the address of this quote?',
  }

  public static UPDATE_QUOTE_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be reset. Are you sure you want to change this quote?',
  }

  public static UPDATE_PRODUCT_QUANTITY_AFTER_DELIVERY_CHARGE_ADDED = {
    //BODY_MESSAGE: 'Applied delivery charge will be re-calculated. Are you sure you want update the quantity of this product?',
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Do you still want to continue?',
  }

  public static ADD_PRODUCT_TO_QUOTE_AFTER_DELIVERY_CHARGE_ADDED = {
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Do you still want to continue? ',
  }

  public static DELETE_PRODUCT_AFTER_CREDIT_APPLIED = {
    BODY_MESSAGE: 'Your applied credit will be removed. Are you sure you want delete this product?',
  }

  public static DELETE_PRODUCT_AFTER_DELIVERY_CHARGE_ADDED = {
    BODY_MESSAGE: 'This quote has already had a manual delivery fee calculated. Proceeding with further edits will require a new delivery fee to be manually applied. Are you sure you want delete this product?',
  }

  public static IS_WAREHOUSE_COLLECTION_YES = 'Y';
  public static IS_WAREHOUSE_COLLECTION_NO = 'N';

  public static IS_USED_OWN_TRANSPORT_YES = 'Y';
  public static IS_USED_OWN_TRANSPORT_NO = 'N';

  public static FILE_UPLOAD_CONSTANTS = {
    IMAGE_TYPE_VALIDATION_MESSAGE: 'Please upload image',
    IMAGE_SIZE_VALIDATION: 'Maximum file size to upload is 40MB .'
  }

  public static UPLOAD_PDF = {
    UPLOAD_PDF_TYPE_VALIDATION_MESSAGE: 'Please upload PDF only',
    UPLOAD_PDF_SIZE_VALIDATION: 'Please upload less than 40 MB file'
  }

  public static MULTI_UPLOAD_FILE = {
    UPLOAD_FILE_TYPE_VALIDATION_MESSAGE: 'Only files with the following extensions are allowed: jpg, jpeg, png, and pdf.',
    UPLOAD_FILE_TYPE_VALIDATION_MESSAGE_PARTIAL_PAYMENT: 'Only images and PDF files are allowed.',
    UPLOAD_FILE_SIZE_VALIDATION: 'Please upload less than 40 MB file'
  }

  public static SUCCESS_MESSAGE_ON_PAYMENT_RECEIPT_UPLOAD = 'Payment remittance has been uploaded successfully.';
  public static PARTIAL_PAYMENT_RECEIPT_REQUIRED = 'Please select file.';
  public static SUCCESS_MESSAGE_ON_PARTIAL_PAYMENT_RECEIPT_DELETE = 'Payment remittance has been deleted successfully.';
  public static ERROR_MESSAGE_ON_PAYMENT_RECEIPT_UPLOAD = 'Error while uploading payment remittance.';

  public static ALLOWED_UPLOAD_SIZE_MB_PDF = 40;
  public static CREDIT_CARD_MERCHANT_SERVICE_FEE_FOR_DOMESTIC = 1.87;
  public static CREDIT_CARD_MERCHANT_SERVICE_FEE_FOR_INTERNATIONAL = 2.97;
  public static CREDIT_CARD_TRANSACTION_FEE = 0.33;

  public static IS_DEPOSIT_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_DEPOSIT_PAYMENT_PROCESSING_NO = 'N';

  public static IS_FINAL_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_FINAL_PAYMENT_PROCESSING_NO = 'N';

  public static IS_FULL_PAYMENT_PROCESSING_YES = 'Y';
  public static IS_FULL_PAYMENT_PROCESSING_NO = 'N';

  public static BLOCK_DATE = '2024-01-16'

  //Payment Status Message
  public static REMAINING_BALANCE = 'Remaining Balance';
  public static FULL_PAYMENT_DUE = 'Full Payment Due';
  public static FINAL_PAYMENT_DUE = 'Final Payment Due';
  public static FINAL_PAYMENT_REMAINING = 'Final Payment Remaining';
  public static DEPOSIT_PAYMENT_DUE = 'Deposit Payment Due';
  public static PAYMENT_PROCESSING = 'Payment Processing';
  public static SITE_CONTACT_DELIVERY_DETAIL_UPDATE = 'Site contact delivery detail has been updated successfully.'
  public static ORDER_TYPE_QUOTE_SHORT = 'Q';
  public static ORDER_TYPE_ORDER_SHORT = 'O';
  public static ORDER_TYPE_SAMPLE_SHORT = 'SO';
  public static DEPOSIT_PAYMENT_PROCESSING = 'Deposit Payment Processing';
  public static DEPOSIT_PAYMENT_IMMINENT = ' Deposit Expiry Imminent';

 public static DELIVERY_INSTRUCTION_UPDATE = 'Delivery instruction has been updated successfully.'
  public static DELIVERY_INSTRUCTION_ADDED = 'Delivery instruction has been added successfully.'

  public static ORDER_STATUS_DISPLAY_FOR_INVOICE_LINK = 'invoice_link';
  public static ORDER_STATUS_DISPLAY_FOR_TRACKING_LINK = 'tracking_link';

  public static ORDER_GOODS_DELIVERED_SHORT = 'GDDE';
  public static IS_CONFIRM_STOCK_REQUIRED_DATE_YES = 'Y';
  public static IS_CONFIRM_STOCK_REQUIRED_DATE_NO = 'N';
  public static GOODS_DISPATCHED_MILESTONE_STATUS_ARRAY = ['GDUNS', 'GDOTT', 'GDA', 'GDD', 'GD', 'GDCO', 'GDDE', 'CAN', 'FU', 'C'];
  public static STOCK_REQUIRED_DATE_UPDATE = 'Stock required date has been updated successfully.'

  public static QUOTE_STATUS_FINAL_PAYMENT = 'Final Payment Pending';
  public static BADGE_TITLE_FINAL_PAYMENT_OUTSTANDING = 'Final Payment Outstanding';
  public static BADGE_TITLE_BALANCE_REMAINING = 'Balance Remaining';
  public static TRUCK_TYPE_CLIENT_ORGANIZED = 'Client Organized';

  public static TENTATIVE_STOCK_REQUIRED_DATE_LABEL = 'Tentative Stock Required Date';
  public static NOMINATE_DELIVERY_DATE_LABEL = 'Nominate Stock Required Date';
  public static STOCk_REQUIRED_DATE_CONFLICT_LABEL = 'Stock Required Date Conflict';
  public static FINAL_PAYMENT_REMAINING_LABEL = 'Final Payment Remaining';
  public static FINAL_PAYMENT_DUE_LABEL = 'Final Payment Due';
  public static PAYMENT_PROCESSING_LABEL = 'Payment Processing';
  public static DEPOSITE_PAYMENT_DUE_LABEL = 'Deposit Payment Due';
   public static TENTATIVE_QUOTE_STOCK_REQUIRED_DATE_LABEL='Tentative Stock Required Date Added';
  public static NOMINATE_QUOTE_DELIVERY_DATE_LABEL='Nominate Stock Required Date '
  public static QUOTE_EXPIRED = "Expired"

public static STATUS_ALERT_MESSAGES = [
    {
      type: 'payment',
      className: 'final-payment-remaining',
      label: this.FINAL_PAYMENT_REMAINING_LABEL,
      message: 'Your order still has an outstanding balance. You’ll be notified once the final payment is due.'
    },
    {
      type: 'payment',
      className: 'final-payment-due',
      label: this.FINAL_PAYMENT_DUE_LABEL,
      message: 'The outstanding balance of your order is now due. It\'s important to note that all of our transport services are outsourced, meaning it typically takes around 5 working days to secure a truck booking. Our team will not commence the delivery booking process, until any owed sum has been paid in full. To avoid dispatching delays, please action this immediately.'
    },
    {
      type: 'payment',
      className: 'final-payment-remaining',
      label: this.PAYMENT_PROCESSING_LABEL,
      message: 'BPAY payments may take up to 3 business days to clear. Our team will endeavour to reduce this timeframe, but can only do so if you\'ve provided us with the applicable payment remittance. If you did not upload proof of payment as the final step in our checkout gateway, please send a copy to hi@gatherco.com.au'
    },
    {
      type: 'date',
      className: 'final-payment-remaining',
      label: this.TENTATIVE_STOCK_REQUIRED_DATE_LABEL,
      message: 'A tentative delivery date has been added to order. This is not set. A member of our despatching team will be in contact again for final confirmation before coordinating delivery. These icons and descriptions must appear on both the invoice live-links and corresponding tracking live-links. Customers must have the ability to apply SRD’s from both the invoice and tracking live links.'
    },
    {
      type: 'date',
      className: 'final-payment-due',
      label: this.NOMINATE_DELIVERY_DATE_LABEL,
      message: 'Please nominate your preferred delivery date. The date you specify is not confirmed. It simply allows our despatching team to apply the relevant level of priority to each order. A member of our despatching team be in contact again for final confirmation before coordinating delivery.'
    },
    {
      type: 'date',
      className: 'final-payment-due',
      label: this.STOCk_REQUIRED_DATE_CONFLICT_LABEL,
      message: 'The specified stock required date is not compatible with the anticipated availability of your order. Please nominate a new delivery date.'
    },
    {
      type: 'payment',
      className: 'deposite-payment-due',
      label: this.DEPOSITE_PAYMENT_DUE_LABEL,
      message: 'The deposit sum is now due. No order will be processed until your transaction has cleared. This payment gateway has an expiry period of 7 days.'
    },
    {
      type: 'payment',
      className: 'final-payment-remaining',
      label: this.DEPOSIT_PAYMENT_PROCESSING,
      message: 'BPAY payments may take up to 2 business days to clear. Our team will endeavour to reduce this timeframe, but can only do so if you\'ve provided us with the applicable payment remittance. If you did not upload proof of payment as the final step in our checkout gateway, please send a copy to hi@gatherco.com.au.'
    },
    {
      type: 'payment',
      className: 'deposite-payment-due',
      label: this.DEPOSIT_PAYMENT_IMMINENT,
      message: 'Your deposit payment is due and now less than 3 days from expiry. Stock is only ever secured or put into production, following payment of the applicable deposit sum. If you\'re still intending to proceed with this order, please take immediate action.'
    },
    {
      type: 'date',
      className: 'final-payment-remaining',
      label: this.TENTATIVE_QUOTE_STOCK_REQUIRED_DATE_LABEL,
      message: 'You have added a tentative stock required date to your quote. The nominated date is not confirmed. Should this order go ahead, we\'ll be in contact again for confirmation before coordinating delivery.'
    },
    {
      type: 'date',
      className: 'deposite-payment-due',
      label: this.NOMINATE_QUOTE_DELIVERY_DATE_LABEL,
      message: 'Please nominate your preferred stock required date. The date you specify is not confirmed. It simply allows our team to apply the relevant level of priority to each project. Should this order go ahead, we\'ll be in contact again for confirmation before coordinating delivery.'
    },
    {
      type: '',
      className: 'deposite-payment-due',
      label: this.QUOTE_EXPIRED,
      message: 'This quote has expired and is no longer valid. Please contact your assigned sales rep to request reactivation. Reactivated quotes are subject to all relevant price increases and stock availability.'
    }
 ];
  public static CLIENT_ORGANIZE_TOOLTIP_TITLE = 'Client Organized';
  public static CLIENT_ORGANIZE_TOOLTIP_MESSAGE = 'You have opted to facilitate your own transport. As such, the collection of this order is your responsibility to finance and coordinate. We kindly request written notice, no less than 3 working days prior to collection.';

  public static GOOGLE_REVIEW_LINK = 'https://g.page/r/CXIpGHQsWMdMEB0/review';
  public static WAREHOUSE_ARRIVAL_DATE_TOOLTIP_MESSAGE = 'Although we do everything possible to ensure accuracy, please note that this date is approximate only. Our team will notify you immediately of any delays.'
  public static LEAD_TIME_TOOLTIP_MESSAGE = 'This lead is a guide only and subject to change. Your assigned sales rep can provide further insight if the detail lead is not compitable with your established construction schedule.'
  public static WAREHOUSE_COLLECTION_ADDRESS_TOOLTIP_MESSAGE = 'You have opted to facilitate your own transport. As such, the collection of this order is your responsibility to finance and coordinate. We kindly request written notice, no less than 3 working days prior to collection.'
  public static PREVENT_DOWNLOAD_ERROR_MESSAGE = 'You need to log in to download this. Please log in to proceed.';
   public static PREVENT_ACCESS_PRICE_LIST_ERROR_MESSAGE = 'You need to log in to access the price list. Please log in to proceed.';

   public static SAMPLE_PRODUCT_IMAGE = 'SPI';
   public static SAMPLE_FORMAT_HERO_IMAGE = 'SFHI';
}
